@import '../styles/colors.scss';

.confirm-dialog-content{
  padding: 0px 20px 20px 20px;
  white-space: pre-line;
  color:$textBlueDark;
}
.confirm-dialog-title{
  font-size: 25px;
}
.confirm-dialog-subtitle{
  font-size: 20px;
  
}
.confirm-dialog-buttons{
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;    
  justify-content: flex-end;
}
.confirm-dialog-button-no{
  margin-right: 20px!important;
}
