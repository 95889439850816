@import './components/styles/colors.scss';

.App {
  text-align: center;
  width: 100%;
  overflow: auto;
  height: 100vh;
  display: flex;
  box-sizing: border-box;

}

a {
  color: inherit;
text-decoration: none;
}


.page{
  display: flex;
  flex: 1px;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  text-align: left;
}

.page-content{
  flex:1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.global-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  outline: none;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;

  .MuiBox-root {
    border: none;
  }
  .MuiFormLabel-root {
    font-size: 0.8rem;
  }
  
}

* {
  scrollbar-width: thin;
  scrollbar-color: $blueDark $grey;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}
*::-webkit-scrollbar-track {
  background: $grey;
  border-radius: 20px;
}
*::-webkit-scrollbar-thumb {
  background-color: $blueDark;
  border-radius: 20px;
}
