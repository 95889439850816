.furnitureContainer{
 max-height: 610px;
 overflow: auto;

}

.furnitureList{
  display:flex;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
}
.furnitureListHeader{
  display:flex;
  flex:1;
  flex-direction: row;
  gap: 10px;
  font-weight: bold;
  div{
    flex:1;

  };
}
.row{
  display:flex;
  flex:1;
  flex-direction: row;
  gap: 10px;
  div{
    flex:1;
  };
}
