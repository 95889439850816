.quitus-container {
  display: flex;
  flex-direction: row;
  flex:1;
}
.quitus-column{
flex:1;
display: flex;
flex-direction: column;
}
.quitus-medias-list{
  flex:1;
display: flex;
flex-direction: row;
flex-wrap: wrap;
}
