@import '../../styles/colors.scss';

.buttonLoader{
  right: 10px;
  position: absolute;
}
// .MuiButton-contained.Mui-disabled{
//   background-color:  rgba(255, 255, 255, 0.12)!important;
//   color:  rgba(255, 255, 255, 0.26)!important;
// }
