@import '../components/styles/colors.scss';

.referential-page {
  display: flex;
  flex-direction: column;
}

.referential-content {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid lightgrey;
  align-self: flex-start;
  margin: 20px;
  padding: 10px;
  color: $blue;
}

.referential-drop {
  text-align: left;
  padding: 10px;
}

#download-referential{
  display: flex;
  align-items: baseline;
}

#download-referential p{
  width: 60%;
}


//WEB small
@media (min-width: 767px) {

  .referential-content {
    flex-direction: row;
  }
  

}