@import '../components/styles/colors.scss';

* {
  scrollbar-width: thin;
  scrollbar-color: $blueDark $grey;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  background: $grey;
  border-radius: 20px;
}
*::-webkit-scrollbar-thumb {
  background-color: $blueDark;
  border-radius: 20px;
}
.additionalhours-list{
flex:1;
min-height: 0;
margin: 10px;

}

.item-list{
  overflow: auto;
  width: 90%;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
}

.list-column-title{
  display:flex;
  align-self: center;
  flex-direction: row;
  width: 90%;
}
.delete-dialog-content{
  padding: 0px 20px 20px 20px;
  color:$textBlueDark;
}
.delete-dialog-title{
  font-size: 25px;
}
.delete-dialog-subtitle{
  font-size: 20px;
  
}
.delete-dialog-buttons{
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;    
  justify-content: flex-end;
}
.delete-dialog-button-no{
  margin-right: 20px!important;
}


//WEB large
@media (min-width: 1450px) {
  .item-list{
    width: 90%;
  }  
  .list-column-title{
    width: 90%;
  }
}
