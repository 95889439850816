@import '../../styles/colors.scss';

.passwordInputContainer{
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
} 

.hideIcon{
  position: absolute;
  right: 12px;
} 

.login-page{
  background-image:url('../../assets/bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}

.login-picto-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 10px;
}

.login-card-container{
  flex:1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & form {
    display: flex;
    min-width: 200px;
    flex-direction: column;
    align-items: center;
    & > * {
      width: 100%;
    }
  }
}

.login-logo{
  height: 40%;
  max-width: 100%;
}

.login-card {
  flex-direction: column;
  align-items: stretch;
  margin-top: 5vh;
  padding: 20px;
  min-width: 200px;
  width: 20%;
  background-color: transparent;
}

.login-input {
  box-sizing: border-box;
  max-width: 350px;
  width: 100%;
  border-radius: 56px;
  padding: 10px;
  outline: none;
  background-color: transparent !important;
  color: white;
  border: none;
  box-shadow: 0 0 0px 2px white;
  margin: 20px 0;
  font-size: large;
  text-align: center;
  &::placeholder {
    color: rgba(255,255,255,.5)
  }
  &:focus{
  box-shadow: 0 0 6px 2px white;

  }
}

#connection{
  font-size: 14px;
  width: 100%;
  box-shadow: none;
}


.loginFormItem {
  margin-bottom: 30px !important;
  height: 56px;
}

.login-title{
  margin: 20px;
    font-weight: 300;
    & b{
      font-weight: 900;
    }
}

.button-bg {
  background-color: $light;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  & > * {
    flex:1;
  }
}
.logo {
  display: inline-block;
  align-self: flex-start;
}

.icon{
  color: gray;
  padding: 3px;
}

#reset-password-info {
  position: absolute;
  top: -30px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}



#connect {
  font-size: 14px;
  color: $blue;
  text-transform: uppercase;
  text-align: center;
  cursor:pointer;
}

#connect:hover {
  color:white;
}


#info-icon {
  align-self: flex-end;
  margin-right: 13%;
}
