@import '../styles/colors.scss';


.menu{
  width: 256px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
  // background-image:url('../../assets/bg.svg');
  // background-repeat: no-repeat;
  // background-size: cover;
  background: $blueLight;
  color: white;
  & >:last-child{
    margin-top: auto;
    margin-bottom: 10px;
  }
}
.menu-picto-group {
  text-align: center;
  margin-bottom: 20px;
}
.menuItem{
  padding: 10px;
  border:1px solid white;
  margin-left: -1px;
  margin-top: -1px;
  background: $blueLight;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  transform-origin: left;
  & :first-child {
    margin-right: 10px;
  };
}

.menuItem-selected {
  background: $blue;
  position: relative;
  &::after{
    content: " ";
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0;
    background: $blue;
    transform-origin: center;
    transform: translateX(10px) rotateZ(45deg);
  }
}

.MuiDrawer-paper{
  border:none!important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.MuiDrawer-paper > .MuiSvgIcon-root{
  z-index: 9999999;
}
