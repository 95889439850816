@import '../../styles/colors.scss';

.container{
  color: $dark;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;    
}

input {
  color: $dark;
}
