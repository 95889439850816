@import '../../styles/colors.scss';


.autocomplete-container{
  position: relative;
}

.options{
  position: absolute;
  z-index: 200;
  left: 0;
  right: 0;
  border-radius: 4px;
  -webkit-box-shadow: 0.5px 1px 2px 1px $dark;
  -moz-box-shadow: 0.5px 1px 2px 1px $dark;
  -o-box-shadow: 0.5px 1px 2px 1px $dark;
  box-shadow: 0.5px 1px 2px 1px $dark;
  color: white;
}

div.options {
  list-style: none;
  margin: auto;
}

div.options div {
  background: $darkLight;
  padding: 10px;
  font-size: 12px;
}

div.options :first-child {
  border-radius: 4px 4px 0px 0px;
}

div.options :last-child {
  border-radius: 0px 0px 4px 4px;
}

div.options :only-child {
  border-radius: 4px 4px 4px 4px;
}

div.options :hover {
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s all;
}

div.options .option-active {
  font-size: 13px;
  font-weight: bold;
}
