@import '../components/styles/colors.scss';

.account-form{
  margin: 20px;
  display:flex;
  flex-direction: column;
  gap:10px;
  min-width: 100%;
}
.account-form-row {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  gap:10px;
  > * {
    flex:1;
    min-width: 200px;
  }
}
.account-form-row-item{
  display: flex;
  align-items: center;
}
.account-form-endorsment{
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  align-self: center;
}


.holiday-detail{
  background-color: $blue;
  color: white;
  padding-top: 13px;
  padding-bottom: 13px;
  text-align: center;
  border-radius: 4px;
}


//WEB large
@media (min-width: 1450px) {
    
  .account-form{
    min-width: 550px;
  }
}
