.accounts-add-button{
  align-self: flex-start;
  justify-self: flex-end;
  margin-top: auto !important;
  margin-bottom: 20px !important;
}   

.accounts-list {
  flex:1;
  margin: 10px;
  min-height: 0;
}
