$black: #151515;
$grey: #E5E4E4;
$white: #FFFFFF;
$blueDarker: #0c4c7c;
$blueDark: #0F6FBA;
$blue: #007CC0;
$blueLight: #00A9E4;
$blueTopMenu: #F1F9FF;
$textGreyColor: #707070;
$textBlueDark: #28405C;
$red: #b83131;
$green: #61D800;

$fontPrimary: $black;
$fontSecondary: $blue;

:export {
  black: $black;
  grey: $grey;
  white: $white;
  blueDarker : $blueDarker;
  textBlueDark:$textBlueDark;
  blueDark: $blueDark;
  blue: $blue;
  green: $green;
  blueLight: $blueLight;
  blueTopMenu: $blueTopMenu;
  textGreyColor: $textGreyColor;
  red:$red;

  fontPrimary: $fontPrimary;
  fontSecondary: $fontSecondary;
}
