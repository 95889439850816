@import '../components/styles/colors.scss';

.item-list{
  overflow: auto;
  width: 80%;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
}

.list-column-title{
  display:flex;
  align-self: center;
  flex-direction: row;
  width: 80%;
}
.delete-dialog-content{
  padding: 0px 20px 20px 20px;
  color:$textBlueDark;
}
.delete-dialog-title{
  font-size: 25px;
}
.delete-dialog-subtitle{
  font-size: 20px;
  
}
.delete-dialog-buttons{
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;    
  justify-content: flex-end;
}
.delete-dialog-button-no{
  margin-right: 20px!important;
}

.addhours-row-item-btn-yes{
  font-size: 12px;
  height: 20px;
}
.addhours-row-item-btn-no{
  font-size: 12px;
  height: 20px;
}

//WEB large
@media (min-width: 1450px) {
  .item-list{
    width: 60%;
  }  
  .list-column-title{
    width: 60%;
  }
  
  .addhours-row-item-btn-yes{
    font-size: 0.875rem;
    height: 35px;
  }
  .addhours-row-item-btn-no{
    font-size: 0.875rem;
    height: 35px;
  }
}
